var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.htmlTag ? _vm.htmlTag : 'div',{tag:"component",staticClass:"o-content-block"},[_c('BowWrapper',[_c('BowAnimation',{key:"content-block__heading",attrs:{"call":"fade","offset":"33%, 33%","speed":1}},[(_vm.title && _vm.title.length > 0)?_c('BowHeading',{ref:"content-block-headings",staticClass:"text-center text-primary",attrs:{"customSize":"leading-[2rem] text-xl md:text-2xl md:leading-[3.25rem] xl:text-4xl"}},_vm._l((_vm.title),function(t,i){return _c('span',{key:i,class:[
                        t.style_heading === 'font-bold' ? 'font-bold' : '' ]},[_vm._v(_vm._s(t.value_heading))])}),0):_vm._e()],1),_vm._v(" "),_c('BowAnimation',{key:"content-block__text",attrs:{"call":"fade","offset":"33%, 33%","speed":1}},[_c('client-only',[(_vm.text && _vm.text !== '')?_c('BowText',{ref:"content-text",class:[
                        _vm.textColumns && _vm.textColumns === 2
                            ? 'lg:columns-2 lg:gap-20 lg:space-y-4'
                            : '',
                        'leading-[2rem] text-base xl:text-xl' ],attrs:{"align":_vm.textColumns && _vm.textColumns === 2
                            ? 'text-left'
                            : 'text-center'},domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }