//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultBlockProps } from "~/helpers/data";

export default {
    name: "BowContentBlock",

    props: {
        ...defaultBlockProps,

        textColumns: {
            default: 1,
            type: Number,
        },
    },

    data: () => ({}),
};
